body {
  margin: 0;
  font-family: Gilroy, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d256e0e09a46375923c_Radomir%20Tinkov%20-%20Gilroy-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d25b27a562aa2904d49_Radomir%20Tinkov%20-%20Gilroy-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d25a5916f045c9d709c_Radomir%20Tinkov%20-%20Gilroy-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d256e0e090c6f75923d_Radomir%20Tinkov%20-%20Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d255617874c51273d41_Radomir%20Tinkov%20-%20Gilroy-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d26cf49e7979fccf0be_Radomir%20Tinkov%20-%20Gilroy-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d26f78e032cbac95be4_Radomir%20Tinkov%20-%20Gilroy-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d26fd2b2cd723800dfd_Radomir%20Tinkov%20-%20Gilroy-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.cart-list {
  min-width: 400px;
  display:none;
}

.cart-main {
  width: 100%;
}

@media screen and (min-width: 768px){
  .discount-payment {
    display: none;
  }
  .cart-main {
   max-width: 800px;
  }
  .cart-list {
    display: block;
  }
}
.lds-roller {
  display: inline-block;
  position: relative;
  transform: scale(0.8);
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #282bf6;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.strike-through-product {
  text-decoration: line-through;
}

input.hs-button.primary.large {
  background: #0047ba;
  border: none;
  font-size: 1em;
  padding: 17px;
  font-weight: normal;
  width: 100%;
}


input.hs-button.primary.large:hover {
   background: #0047ba;
  border: none;
  font-size: 1em;
  padding: 17px;
  font-weight: normal;
  width: 100%;
}